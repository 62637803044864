import React from 'react';
import { useCart } from '../contexts/CartContext';
import './cart.css';

const Cart = () => {
  const { cart, updateQuantity, removeFromCart, totalAmount } = useCart();

  const incrementQuantity = (title, color) => {
    updateQuantity(title, color, 1);
  };

  const decrementQuantity = (title, color) => {
    updateQuantity(title, color, -1);
  };

  const handleRemove = (title, color) => {
    removeFromCart(title, color);
  };

  return (
    <div className="cart-container">
      <h1>Your Cart</h1>
      <div className="cart-items-wrapper">
        {cart.length === 0 ? (
          <p className="empty-cart-message">Your cart is empty</p>
        ) : (
          <ul className="cart-items">
            {cart.map((item, index) => {
              const discount = item.listingPrice - item.sellingPrice;

              return (
                <li key={index} className="cart-item">
                  <img src={item.image} alt={item.title} className="cart-item-image" />
                  <div className="cart-item-details">
                    <h2>{item.title}</h2>

                    <p className="price-details">
                      <span className="listing-price">
                        ₹{item.listingPrice.toFixed(2)}
                      </span>
                      <span className="discount-price">
                        -₹{discount.toFixed(2)}
                      </span>
                      <span className="selling-price">
                        ₹{item.sellingPrice.toFixed(2)}
                      </span>
                    </p>

                    <div className="cart-item-quantity">
                      <button onClick={() => decrementQuantity(item.title, item.color)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => incrementQuantity(item.title, item.color)}>+</button>
                      <button className="delete-button" onClick={() => handleRemove(item.title, item.color)}>Remove</button>
                    </div>

                    <p className="total-price">Total Price: ₹{item.totalPrice.toFixed(2)}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {cart.length > 0 && (
        <div className="cart-total">
          <h2>Total: ₹{totalAmount.toFixed(2)}</h2>
        </div>
      )}
    </div>
  );
};

export default Cart;
