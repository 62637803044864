import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './footer/Footer';
import './components/navbar/navbar.css';
import Shop from './pages/Shop';
import Home from './pages/Home';
import RelatedProducts from './pages/RelatedProducts';
import Checkout from './pages/Checkout';
import Login from './pages/Login';
import Register from './pages/Register';
import AllReviews from './pages/AllReviews';
import ChangeUserDetails from './my account/ChangeUserDetails';  
import MyOrders from './my account/MyOrders';
import UserProfile from './my account/UserProfile';
import ProductDetails from './pages/ProductDetails';
import AdminLogin from './admin/AdminLogin';
import AdminPage from './admin/AdminPage'; 
import AdminReturnOrders from './admin/AdminReturnOrders';
import AdminQueries from './admin/AdminQueries';
import UserOrders from './admin/UserOrders';
import UpdateOrder from './admin/UpdateOrder';
import UpdateRank from './admin/UpdateRank';
import AddImageToProduct from './admin/AddImageToProduct';
import AdminRegister from './admin/AdminRegister';
import ProductList from './admin/ProductList';
import Cart from './pages/Cart'; 
import UploadProduct from './admin/UploadProduct';
import Dashboard from './admin/Dashboard'; 
import UpdateProduct from './admin/UpdateProduct';
import Products from './admin/Products'; 
import Orders from './admin/Orders'; 
import UpdateOrderInBulk from './admin/UpdateOrderInBulk';
import ManageUsers from './admin/ManageUsers';
import { UserProvider } from './contexts/UserContext';
import { CartProvider } from './contexts/CartContext';
import { AuthProvider } from './contexts/AuthContext'; 
import ProtectedRoute from './routes/ProtectedRoute'; 
import Account from './my account/Account';
import ForgetPassword from './my account/ForgetPassword';
import ReturnOrder from './my account/ReturnOrder';
import Invoice from './admin/Invoice';
import AboutUs from './pages/AboutUs';
import ReturnPolicy from './pages/ReturnPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import ScrollToTop from './pages/ScrollToTop';
import ForgetPasswordreset from './pages/Forgetpasswordreset';
import ReactGA from 'react-ga4'; // Import ReactGA from react-ga4
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';


// Your Google Analytics Measurement ID
const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

const TrackPageView = () => {
  useEffect(() => {
    const sendPageView = () => {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    };
    sendPageView();
    window.addEventListener('popstate', sendPageView);
    return () => window.removeEventListener('popstate', sendPageView);
  }, []);

  return null;
};

const App = () => {
  useEffect(() => {
    if (MEASUREMENT_ID) {
      ReactGA.initialize(MEASUREMENT_ID); // Initialize Google Analytics
    } else {
      console.error("GA_MEASUREMENT_ID is missing");
    }
  }, []);

  return (
    <UserProvider>
      <CartProvider>
        <AuthProvider>
          <Router>
            <ScrollToTop/>
            <Navbar />
            <TrackPageView /> {/* Track page views */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/allreviews" element={<AllReviews />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/change-user-details" element={<ChangeUserDetails />} />
              <Route path="/product/:productId/:color" element={<ProductDetails />} />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/account/*" element={<Account />} />
              <Route path="/User-Profile" element={<UserProfile />} />
              <Route path="/My-orders" element={<MyOrders />} />
              <Route path='/forget-password' element={<ForgetPassword/>}/>
              <Route path='/return-order' element={<ReturnOrder/>}/>
              <Route path='/about-us' element={<AboutUs/>}/>
              <Route path='/return-policy' element={<ReturnPolicy/>}/>
              <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
              <Route path='/related-products' element={<RelatedProducts/>}/>
              <Route path='/forget-password-reset' element={<ForgetPasswordreset/>}/>

              <Route path='/Contact-us' element={<ContactUs/>}/>
              <Route path='/Privacy-policy' element={<PrivacyPolicy/>}/>
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

              {/* Admin Routes */}
              <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="products" element={<Products />} />
                <Route path="orders" element={<Orders />} />
                <Route path="manage-users" element={<ManageUsers />} />
                <Route path="Add-Image" element={<AddImageToProduct />} />
                <Route path="product-list" element={<ProductList />} />
                <Route path="admin-register" element={<AdminRegister />} />
                <Route path="upload-product" element={<UploadProduct />} />
                <Route path="update-product" element={<UpdateProduct />} />
                <Route path="update-Metadata" element={<UpdateRank />} />
                <Route path="update-order" element={<UpdateOrder />} />
                <Route path="user-order" element={<UserOrders />} />
                <Route path="invoice" element={<Invoice />} />
                <Route path="queries" element={<AdminQueries />} />
                <Route path="admin-return-orders" element={<AdminReturnOrders />} />
                <Route path="Update-bulk-orders" element={<UpdateOrderInBulk />} />
              </Route>
            </Routes>
            <Footer />
          </Router>
        </AuthProvider>
      </CartProvider>
    </UserProvider>
  );
};

export default App;
