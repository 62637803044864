import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './allreviews.css'; 

const AllReviews = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null); // State to track the enlarged image

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('/api/reviews'); // Fetch all reviews from your API
        setReviews(response.data || []);
      } catch (err) {
        setError(err.response ? err.response.data.error : 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  // Filter and sort reviews by highest to lowest rating
  const filteredReviews = productId
    ? reviews.filter(review => review && review.productId && review.productId.toString() === productId)
    : reviews;

  const sortedReviews = filteredReviews.sort((a, b) => b.rating - a.rating);

  const toggleImageSize = (index) => {
    setEnlargedImage(enlargedImage === index ? null : index);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className={`star ${i < rating ? 'filled' : ''}`}>
          ★
        </span>
      );
    }
    return stars;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2><center>All User Reviews</center></h2>
      {sortedReviews.length > 0 ? (
        <>
          {sortedReviews.slice(0, showMore ? sortedReviews.length : 1).map((review, index) => (
            <div className="review-container" key={index}>
              <div className="review-rating"><h>Rating:</h> 
                {renderStars(review.rating)}
              </div>
              <div className="review-comment">Comment: {review.comment}</div>
              {review.imageUrl && (
                <div>
                  <img
                    className={`reviewImg ${enlargedImage === index ? 'enlarged' : ''}`}
                    src={`${process.env.REACT_APP_BASE_URL}${review.imageUrl}`} // Using environment variable for image URL
                    alt={`Review for Product ID: ${review.productId}`}
                    onClick={() => toggleImageSize(index)}
                    onError={(e) => (e.target.src = 'https://example.com/path/to/placeholder-image.jpg')}
                  />
                </div>
              )}
              <div className="review-date">Review Date: {new Date(review.reviewDate).toLocaleDateString()}</div>
            </div>
          ))}
          {!showMore && sortedReviews.length > 1 && (
            <button onClick={() => setShowMore(true)} className='reviewBtn'>Show More Reviews</button>
          )}
          {showMore && (
            <button onClick={() => setShowMore(false)} className='reviewBtn'>Show Less Reviews</button>
          )}
        </>
      ) : (
        <p>No reviews found.</p>
      )}
    </div>
  );
};

export default AllReviews;
