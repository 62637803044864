import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './productDetails.css';
import { useCart } from '../contexts/CartContext';
import offerTag from '../components/assets/offer.png';
import AllReviews from './AllReviews';
import RelatedProducts from './RelatedProducts'; // Import RelatedProducts component
import { FaShareAlt } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable'; // Import useSwipeable for swipe functionality

const ProductDetails = () => {
  const { productId, color } = useParams(); // Extract URL parameters
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [selectedColorImages, setSelectedColorImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false); // State for "Read More" functionality
  const [showAllImages, setShowAllImages] = useState(false); // State for "View More" and "View Less" functionality
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // For swipe functionality

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const shareLink = process.env.REACT_APP_SHARE_LINK;

  // Swipe handlers for mobile view (unconditionally called)
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextImage(),
    onSwipedRight: () => handlePrevImage(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // Fetch product details when component mounts
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}/product/${productId}/${color}`);
        setProduct(response.data);
        setSelectedColorImages(response.data.selectedColorImages);
        setMainImage(`${baseUrl}/${response.data.selectedColorImages[0]}`);
        setSelectedColorImages(response.data.selectedColorImages);
        setSelectedColor(response.data.selectedColor);
      } catch (error) {
        console.error('Error fetching product details:', error);
        navigate('/'); // Redirect to home page if there is an error
      }
    };

    fetchProductDetails();
  }, [productId, color, navigate, baseUrl]);

  // Early return in case the product is still loading
  if (!product) {
    return <div>Loading...</div>; // Loading state while fetching product data
  }

  const { title, name, shortDescription, listingPrice, sellingPrice, details, longDescription, about, allColors, categoryId } = product;

  const handleAddToCart = () => {
    const productData = {
      image: mainImage,
      title: name, // Use the product name as the title
      description: shortDescription,
      listingPrice,
      sellingPrice,
      details: longDescription,
      about,
      images: selectedColorImages,
      color: selectedColor,
      productId,
    };
    addToCart(productData);
  };

  const handleBuyNow = () => {
    const productData = {
      image: mainImage,
      title: name,
      description: shortDescription,
      listingPrice,
      sellingPrice,
      details: longDescription,
      about,
      images: selectedColorImages,
      color: selectedColor,
      productId,
    };

    if (localStorage.getItem('token')) {
      addToCart(productData);
      navigate('/checkout');
    } else {
      localStorage.setItem('pendingProduct', JSON.stringify(productData));
      navigate('/register');
    }
  };

  const handleColorChange = (colorImages, colorName) => {
    setSelectedColorImages(colorImages);
    setSelectedColor(colorName);
    setMainImage(`${baseUrl}/${colorImages[0]}`);
  };

  const percentageDifference = Math.round(((listingPrice - sellingPrice) / listingPrice) * 100);

  const shareProduct = () => {
    const shareUrl = `${shareLink}/product/${productId}/${selectedColor}`;
    if (navigator.share) {
      navigator.share({
          title: name,
          url: shareUrl,
        }).catch(console.error);
    } else {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(name)}`, '_blank');
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription); // Toggle between full description and truncated description
  };

  const toggleImageView = () => {
    setShowAllImages(!showAllImages); // Toggle between showing all images and limited images
  };

  const imagesToShow = showAllImages ? selectedColorImages : selectedColorImages.slice(0, 4); // Show 4 images by default

  const handleNextImage = () => {
    const newIndex =
      currentImageIndex === selectedColorImages.length - 1
        ? 0
        : currentImageIndex + 1;
    setCurrentImageIndex(newIndex);
    setMainImage(`${baseUrl}/${selectedColorImages[newIndex]}`);
  };

  const handlePrevImage = () => {
    const newIndex =
      currentImageIndex === 0
        ? selectedColorImages.length - 1
        : currentImageIndex - 1;
    setCurrentImageIndex(newIndex);
    setMainImage(`${baseUrl}/${selectedColorImages[newIndex]}`);
  };

  return (
    <div className="product-details-page">
      <div className="product-details-container">
        <div className="image-gallery">
          <div className="thumbnail-container">
            {imagesToShow.map((img, index) => (
              <img
                key={index}
                src={`${baseUrl}/${img}`}
                alt={`${name} ${index + 1}`}
                className="thumbnail"
                onMouseOver={() => setMainImage(`${baseUrl}/${img}`)}
              />
            ))}
            <button className="toggle-image-view-btn" onClick={toggleImageView}>
              {showAllImages ? 'View Less' : 'View More'}
            </button>
          </div>

          {/* Swipeable main image */}
          <div className="main-image-container" {...handlers}>
            <div className="sales-tag">
              <img src={offerTag} alt="Sales Tag" className="sales-tag-image" />
              <span className="sales-tag-text">{percentageDifference}%</span>
            </div>
            <img src={mainImage} alt={name} className="main-image" />
          </div>
        </div>
         {/* Add the image indicator dots */}
           <div className="image-indicators">
           {selectedColorImages.map((_, index) => (
            <span
            key={index}
           className={`indicator-dot ${index === currentImageIndex ? 'active-dot' : ''}`}
           ></span>
           ))}
          </div>

        <div className="product-info">
          <h1>{name}</h1>
          <p className="product-description">{shortDescription}</p>
          <p className="product-price"><strong>List Price: </strong><s>₹{listingPrice}</s></p>
          <p className="product-selling-price"><strong> Price: </strong>₹{sellingPrice}</p>
          <p className="percentage-difference">Save {percentageDifference}%</p>
          <p className="product-color"><strong>Color: </strong>{selectedColor}</p>
          <p className="product-id"><strong>Product ID: </strong>{productId}</p>

          <div className="purchase-buttons">
            <button className="add-to-cart-btn" onClick={handleAddToCart}>Add to Cart</button>
            <button className="buy-now-btn" onClick={handleBuyNow}>Buy Now</button>
            <FaShareAlt className="share-icon" onClick={shareProduct} />
          </div>
          <div className="other-colors">
            <h2>Other Colors Available</h2>
            <div className="colors-container">
              {allColors.map((colorItem, index) => (
                 <div key={index} className="color-item" onClick={() => handleColorChange(colorItem.images, colorItem.color)}>
                  <p>{colorItem.color}</p>
                  <img
                    src={`${baseUrl}/${colorItem.images[0]}`}
                    alt={`${name} ${colorItem.color}`}
                    className="color-thumbnail"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="product-details">
            <h3>Details</h3>
            <p>
            {showFullDescription ? longDescription : `${longDescription.substring(0, 250)}...`}
            </p>
            <button className="toggle-description-btn" onClick={toggleDescription}>
              {showFullDescription ? 'Show Less' : 'Read More'}
            </button>
          </div>
        </div>
      </div>
      <AllReviews productId={productId} />
      <RelatedProducts categoryId={categoryId} currentProductId={productId} />
    </div>
  );
};

export default ProductDetails;
