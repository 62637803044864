import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './aboutUs.css';
import happyCustomer from '../components/assets/happycustomer.jpg';
import visionImg from '../components/assets/ourvision.jpg';
import ourstoryImg from '../components/assets/ourstory.jpg';

const AboutUs = () => {
    const countRef = useRef(null);
    const [isCounting, setIsCounting] = useState(false);

    useEffect(() => {
        // Handle image transitions
        const images = document.querySelectorAll('.imageabout img, .Aboutimage img');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                } else {
                    entry.target.classList.remove('slide-in');
                }
            });
        }, { threshold: 0.1 });

        images.forEach(image => {
            observer.observe(image);
        });

        // Clean up the observer on unmount
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        // Handle counting animation
        const startCountAnimation = () => {
            if (countRef.current) {
                let start = 100;
                const end = 10000;
                const duration = 2000;
                const stepTime = 50;
                const stepCount = Math.ceil(duration / stepTime);
                const stepIncrement = (end - start) / stepCount;

                let current = start;
                const interval = setInterval(() => {
                    if (countRef.current) { // Check if countRef.current is not null
                        current += stepIncrement;
                        if (current >= end) {
                            clearInterval(interval);
                            countRef.current.innerText = '10,000+';
                        } else {
                            countRef.current.innerText = Math.floor(current).toLocaleString();
                        }
                    } else {
                        clearInterval(interval); // Ensure interval is cleared if countRef is null
                    }
                }, stepTime);
            }
        };

        const handleScroll = () => {
            const element = countRef.current;
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom >= 0) {
                    if (!isCounting) {
                        setIsCounting(true);
                        startCountAnimation();
                    }
                } else {
                    setIsCounting(false);
                }
            }
        };

        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener('scroll', debouncedHandleScroll);
        handleScroll(); // Check visibility on mount

        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [isCounting]);

    return (
        <div className="aboutContainer">
            <h1><center>About Western Flex - Innovating Bag Solutions</center></h1>
            <p>
                Welcome to Western Flex! We believe that the perfect bag is more than just an accessory; it is a trusted companion designed for your journey through life. 
                Whether you're preparing for a tech-driven workday or an adventurous travel escapade, our versatile range of bags is tailored to suit every lifestyle. 
                With a commitment to quality, functionality, and style, Western Flex is proud to provide products that cater to the needs of modern-day explorers.
            </p>

            <section id="aboutPage">
                <div className="text">
                    <h1>Our Story</h1>
                    <p className="p1">
                        Western Flex was founded with a passion for creating durable, stylish, and highly functional bags. Our journey began with the belief that a 
                        well-designed bag can enhance your daily life and adventures. Today, we have expanded our operations with our own in-house production line, 
                        ensuring that we maintain the highest level of control over the quality of our products from design to manufacturing. With every product, we aim to combine durability, 
                        modern design, and sustainability.
                    </p>
                </div>
                <div className="imageabout">
                    <img src={ourstoryImg} alt="Our Story" />
                </div>
            </section>

            <section id="aboutUsPage">
                <div className="AboutUs1">
                    <div className="Aboutimage">
                        <img src={visionImg} alt="Our Vision" />
                    </div>
                    <div className="Aboutcontent">
                        <h1>Our Vision</h1>
                        <p>
                            We envision a world where quality products meet the needs of consumers while reducing environmental impact. 
                            Western Flex's in-house production line enables us to produce bags with sustainability in mind, ensuring minimal waste during production. 
                            Our mission is to lead the industry by creating innovative, long-lasting products that enrich lives while caring for the planet.
                        </p>
                    </div>
                </div>
            </section>

            <section id="aboutPage">
                <div className="text">
                    <h1>Why Choose Us?</h1>
                    <p>
                        Western Flex stands out not only because of our high-quality products but also because of our commitment to customer satisfaction. 
                        Our in-house production facility allows us to ensure every bag meets the highest standards before reaching you. We manage every step of the process 
                        with precision, from selecting premium materials to finalizing the design. This direct approach enables us to keep costs down and quality up, 
                        offering you the best value for your money.
                    </p>

                    <h1>Our Happy Customers</h1>
                    <p>
                        We take great pride in serving our customers and ensuring their satisfaction with our products and services. With over 10,000 satisfied customers 
                        and counting, we continue to strive for excellence.
                    </p>

                    <div className="customerCount">
                        <div className="countItem">
                            <h2><span ref={countRef}>100</span></h2>
                            <p><b>Satisfied Customers</b></p>
                        </div>
                    </div>
                </div>
                <div className="imageabout">
                    <img src={happyCustomer} alt="Happy Customers" />
                </div>
            </section>

            <h1><center>Return Policy</center></h1>
            <div className='returnPolicy'>
            <p>To get policy details <Link to="/return-policy">Click Here</Link></p>
            </div>
        </div>
    );
};

export default AboutUs;

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
