import React, { useState } from 'react';
import './privacyPolicy.css';

const PrivacyPolicy = () => {
  const [isDeviceInfoVisible, setIsDeviceInfoVisible] = useState(false);
  const [isOrderInfoVisible, setIsOrderInfoVisible] = useState(false);
  const [isCustomerSupportVisible, setIsCustomerSupportVisible] = useState(false);
  const [isMinorsVisible, setIsMinorsVisible] = useState(false);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] =useState(false);

  const toggleDeviceInfo = () => {
    setIsDeviceInfoVisible(!isDeviceInfoVisible);
  };

  const toggleOrderInfo = () => {
    setIsOrderInfoVisible(!isOrderInfoVisible);
  };

  const toggleCustomerSupport = () => {
    setIsCustomerSupportVisible(!isCustomerSupportVisible);
  };

  const toggleMinors = () => {
    setIsMinorsVisible(!isMinorsVisible);
  };

  const togglePersonalInfo=()=>{
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  return (
    <section id='PrivacyMain'>
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-header">
        Privacy Policy
      </h1>
      <div className='headingTop'>
        <p><strong>Last Updated: [21 August 2024]</strong></p>

        <center><h2>Collecting Personal Information</h2></center>
        <p1 >When you visit WesternFlex.com, we collect certain information about your device, your interaction with the website, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information.”</p1>
        
        <h3 onClick={toggleDeviceInfo}>Device Information</h3>
        <div className={`privacy-policy-content ${isDeviceInfoVisible ? 'show' : 'hide'}`}>
          <ul>
            <li><strong>Examples of Personal Information collected:</strong> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with WesternFlex.com.</li>
            <li><strong>Purpose of collection:</strong> to load the Site accurately for you and to perform analytics on Site usage to optimize our Site.</li>
            <li><strong>Source of collection:</strong> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.</li>
            <li><strong>Disclosure for a business purpose:</strong> shared with our processor Shopify.</li>
          </ul>
        </div>

        <h3 onClick={toggleOrderInfo}>Order Information</h3>
        <div className={`privacy-policy-content ${isOrderInfoVisible ? 'show' : 'hide'}`}>
          <ul>
            <li><strong>Examples of Personal Information collected:</strong> name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.</li>
            <li><strong>Purpose of collection:</strong> to provide products or services to you to fulfill our contract, process your payment information, arrange for shipping, provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
            <li><strong>Source of collection:</strong> collected from you.</li>
            <li><strong>Disclosure for a business purpose:</strong> shared with our processor Shopify and Western Flex affiliates.</li>
          </ul>
        </div>

        <h3 onClick={toggleCustomerSupport}>Customer Support Information</h3>
        <div className={`privacy-policy-content ${isCustomerSupportVisible ? 'show' : 'hide'}`}>
          <ul>
            <li><strong>Examples of Personal Information collected:</strong> name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.</li>
            <li><strong>Purpose of collection:</strong> to provide customer support.</li>
            <li><strong>Source of collection:</strong> collected from you.</li>
            <li><strong>Disclosure for a business purpose:</strong> shared with our customer support team.</li>
          </ul>
        </div>

        <h3 onClick={toggleMinors}>Minors</h3>
        <div className={`privacy-policy-content ${isMinorsVisible ? 'show' : 'hide'}`}>
          <p1>The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.</p1>
        </div>

        <h3 onClick={togglePersonalInfo}>Using Personal Information</h3>
        <div className={`privacy-policy-content ${isPersonalInfoVisible ? 'show' : 'hide'}`}>
            <p1>We use your Personal Information to provide our services to you, which includes offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.</p1>

        </div>

      </div>
    </div>
    </section>
  );
};

export default PrivacyPolicy;
