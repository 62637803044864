import React, { useState } from 'react';
import axios from 'axios';
import { FaQuestionCircle } from 'react-icons/fa';
import './forgetpasswordreset.css';

function Forgetpasswordreset() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleVerifyDetails = async () => {
    try {
      const response = await axios.post('/api/forgotpassword/verify', {
        phoneNumber,
        securityQuestion,
        securityAnswer,
      });

      if (response.data.success) {
        setShowResetPassword(true);
        setError('');
      } else {
        setError(response.data.message || 'Incorrect details.');
        setShowResetPassword(false);
      }
    } catch (error) {
      console.error('Error verifying details', error);
      setError('Error verifying details.');
      setShowResetPassword(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('/api/forgotpassword/reset', {
        phoneNumber,
        newPassword,
      });

      if (response.data.success) {
        setSuccess('Password reset successful!');
        setError('');
      } else {
        setError(response.data.message || 'Error resetting password.');
      }
    } catch (error) {
      console.error('Error resetting password', error);
      setError('Error resetting password.');
    }
  };

  return (
    <div className="forget-password-reset-container">
      <h2>Forgot Password</h2>
      <div className="forget-password-reset-form-group">
        <input
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="forget-password-reset-input"
        />
      </div>
      <div className="forget-password-reset-form-group">
        <FaQuestionCircle className="forget-password-reset-icon" />
        <select
          name="securityQuestion"
          id="securityQuestion"
          value={securityQuestion}
          onChange={(e) => setSecurityQuestion(e.target.value)}
          className="forget-password-reset-input"
        >
          <option value="">Select a Security Question</option>
          <option value="What is your pet's name?">What is your pet's name?</option>
          <option value="What is your mother's maiden name?">What is your mother's maiden name?</option>
          <option value="What was your first car?">What was your first car?</option>
          <option value="What elementary school did you attend?">What elementary school did you attend?</option>
          <option value="What is the name of your favorite teacher?">What is the name of your favorite teacher?</option>
        </select>
      </div>
      <div className="forget-password-reset-form-group">
        <input
          type="text"
          placeholder="Enter Security Answer"
          value={securityAnswer}
          onChange={(e) => setSecurityAnswer(e.target.value)}
          className="forget-password-reset-input"
        />
      </div>
      <button className="forget-password-reset-button" onClick={handleVerifyDetails}>
        Verify Details
      </button>
      {showResetPassword && (
        <>
          <div className="forget-password-reset-form-group">
            <input
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="forget-password-reset-input"
            />
          </div>
          <div className="forget-password-reset-form-group">
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="forget-password-reset-input"
            />
          </div>
          <button className="forget-password-reset-button" onClick={handleResetPassword}>
            Reset Password
          </button>
        </>
      )}
      {error && <div className="forget-password-reset-error-message">{error}</div>}
      {success && <div className="forget-password-reset-success-message">{success}</div>}
    </div>
  );
}

export default Forgetpasswordreset;
