import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './userreview.css'; // Assuming you'll add the CSS here

const UserReview = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For the enlarged image

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/user/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.error : 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleDeleteReview = async (reviewId) => {
    try {
      const token = localStorage.getItem('token');
      setUser(prevUser => ({
        ...prevUser,
        reviews: prevUser.reviews.filter(review => review.reviewId !== reviewId)
      }));
      await axios.delete(`/api/reviews/${reviewId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      setError(err.response ? err.response.data.error : 'Error deleting review');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const imageBaseURL = process.env.REACT_APP_BASE_URL; // Using environment variable for image base URL

  return (
    <div className="user-review-container">
      <h2>Reviews</h2>
      {user?.reviews?.length > 0 ? (
        user.reviews.map(review => (
          <div key={review.reviewId} className="review-item">
            <h3>Product ID: {review.productId}</h3>
            <p>Rating: {review.rating}</p>
            <p>Comment: {review.comment}</p>
            {review.imageUrl && (
              <div className="review-image-container">
                <img 
                  src={`${imageBaseURL}${review.imageUrl}`} 
                  alt={`Review for Product ID: ${review.productId}`} 
                  className="review-image"
                  onError={(e) => e.target.src = '/path/to/placeholder-image.jpg'} 
                  onClick={() => setSelectedImage(`${imageBaseURL}${review.imageUrl}`)} 
                />
              </div>
            )}
            <p>Review Date: {new Date(review.reviewDate).toLocaleDateString()}</p>
            <button onClick={() => handleDeleteReview(review.reviewId)} className="delete-button">Delete Review</button>
          </div>
        ))
      ) : (
        <p>No reviews found. </p>
      )}

      {/* Modal for enlarged image */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <span className="close-modal">&times;</span>
          <img src={selectedImage} alt="Enlarged review" className="modal-content"/>
        </div>
      )}
    </div>
  );
};

export default UserReview;
