import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import './relatedProducts.css';

const RelatedProducts = ({ currentProductId }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Default for larger screens
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/related-products/${currentProductId}`);
        setRelatedProducts(response.data);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchRelatedProducts();
  }, [currentProductId, baseURL]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsPerPage(3); // Show only 3 products on mobile
      } else {
        setItemsPerPage(4); // Show 4 products on larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial value
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleProductClick = (productId, color) => {
    navigate(`/product/${productId}/${color}`);
  };

  const uniqueProducts = Array.from(new Set(relatedProducts.map(product => product.productId)))
    .map(id => relatedProducts.find(product => product.productId === id));

  const handleNext = () => {
    if (currentIndex + itemsPerPage < uniqueProducts.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const containerStyle = {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: `minmax(200px, 1fr)`,
    gap: '10px',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center', // Center the products
  };

  return (
    <div className="related-products" {...handlers}>
      <h2><center>Related Products</center></h2>
      <div className="products-list-wrapper">
        <button 
          className="navigation-button" 
          onClick={handlePrevious} 
          disabled={currentIndex === 0}
        >
          &lt;
        </button>
        <div className="products-list-container">
          <div className="products-list" ref={containerRef} style={containerStyle}>
            {uniqueProducts.slice(currentIndex, currentIndex + itemsPerPage).map(product => (
              <div 
                key={product.productId} 
                className="product-item" 
                onClick={() => handleProductClick(product.productId, product.colors[0].color)}
              >
                <img 
                  src={`${baseURL}/${product.colors[0].images[0]}`} 
                  alt={product.name} 
                />
                <p className="product-name">{product.name}</p>
                <p className="product-price">₹{product.listingPrice}</p>
                <p className="product-selling-price">₹{product.sellingPrice}</p>
              </div>
            ))}
          </div>
        </div>
        <button 
          className="navigation-button" 
          onClick={handleNext} 
          disabled={currentIndex + itemsPerPage >= uniqueProducts.length}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default RelatedProducts;
