import React from 'react';
import './shippingPolicy.css';

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy-container">
      <h1 className="policy-heading">Western Flex Shop Shipping Policy</h1>
      <p className="policy-text">Country: India</p>
      
      <h2 className="policy-subheading">Delivery Time</h2>
      <p className="policy-text">
        All orders are estimated to be delivered within 3-7 business days. This timeframe includes both handling and transit times.
      </p>
      
      <h2 className="policy-subheading">Order Cut-Off Time</h2>
      <p className="policy-text">
        Orders placed before 2:00 PM (GMT+05:30) will be processed the same day. Orders placed after this cut-off time will be processed the next business day.
      </p>
      
      <h2 className="policy-subheading">Handling Time</h2>
      <ul className="policy-list">
        <li>Minimum: 0 days (orders placed before the cut-off time will be shipped the same day)</li>
        <li>Maximum: 1 day</li>
        <li>Fulfilled from Monday to Saturday.</li>
      </ul>
      
      <h2 className="policy-subheading">Transit Time</h2>
      <ul className="policy-list">
        <li>Minimum: 3 days</li>
        <li>Maximum: 6 days</li>
        <li>Shipped from our facility Monday to Saturday.</li>
      </ul>
      
      <h2 className="policy-subheading">Shipping Cost</h2>
      <p className="policy-text">
        We offer free shipping on all orders, so you can shop with peace of mind!
      </p>
      
      <h2 className="policy-subheading">Public Holidays</h2>
      <p className="policy-text">
        Please note that delivery estimates may be adjusted if your order is affected by public holidays.
      </p>
      
      <p className="policy-text">
        For any questions regarding your order or our shipping policy, please contact our customer support. Thank you for choosing Western Flex Shop!
      </p>
    </div>
  );
};

export default ShippingPolicy;
