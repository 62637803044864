import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './returnorder.css'; // Ensure this file contains necessary styles

const ReturnOrder = () => {
  const location = useLocation();
  const { orderId, items } = location.state || {};

  const [phoneNumber, setPhoneNumber] = useState('');
  const [reason, setReason] = useState('');
  const [images, setImages] = useState({ image1: null, image2: null, image3: null });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [showReturnPopup, setShowReturnPopup] = useState(false);

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setImages(prevImages => ({
      ...prevImages,
      [name]: files[0]
    }));
  };

  const handleCheckboxChange = (event) => {
    const itemName = event.target.getAttribute('data-item-name');
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemName]);
    } else {
      setSelectedItems(selectedItems.filter(name => name !== itemName));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled and at least one image is uploaded
    if (!orderId || !phoneNumber || !reason || selectedItems.length === 0 || 
        (!images.image1 && !images.image2 && !images.image3)) {
      setError('Please fill in all required fields, select at least one item, and upload at least one image.');
      setShowReturnPopup(true);
      return;
    }

    const formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('phoneNumber', phoneNumber);
    formData.append('reason', reason);
    formData.append('image1', images.image1);
    formData.append('image2', images.image2);
    formData.append('image3', images.image3);
    formData.append('selectedItems', JSON.stringify(selectedItems));

    try {
      const response = await axios.post('/api/return-order', formData);
      setMessage('Return request submitted successfully.');
      setError('');
      setShowReturnPopup(true);
    } catch (error) {
      setError('Error submitting return request.');
      setMessage('');
      setShowReturnPopup(true);
    }
  };

  const closeReturnPopup = () => {
    setShowReturnPopup(false);
    setMessage('');
    setError('');
  };

  return (
    <div className="return-order-container">
      <h2>Return Order</h2>
      <p>Order ID: {orderId}</p>

      <h3>Items in this Order:</h3>
      <form onSubmit={handleSubmit}>
        <ul>
          {items && items.map((item) => (
            <li key={item._id} className="checkbox-item">
              <div className="checkbox-image-container">
                <input
                  type="checkbox"
                  data-item-name={item.productName}
                  onChange={handleCheckboxChange}
                  className='checkbox'
                />
                {item.image && (
                  <img
                    src={item.image}
                    alt={`Product ${item.productId}`}
                    className="item-image"
                  />
                )}
                <label className="item-label">
                  {item.productName} - {item.quantity} x ₹{item.price}
                </label>
              </div>
            </li>
          ))}
        </ul>

        <div>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="reason">Reason for Return:</label>
          <textarea
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          ></textarea>
        </div>

        <div>
          <label htmlFor="image1">Upload Image 1:</label>
          <input type="file" id="image1" name="image1" onChange={handleImageChange} />
        </div>
        <div>
          <label htmlFor="image2">Upload Image 2:</label>
          <input type="file" id="image2" name="image2" onChange={handleImageChange} />
        </div>
        <div>
          <label htmlFor="image3">Upload Image 3:</label>
          <input type="file" id="image3" name="image3" onChange={handleImageChange} />
        </div>

        <button type="submit" className="returnBtn">Submit Return Request</button>
      </form>

      {showReturnPopup && (
        <div className="return-popup">
          <div className="return-popup-content">
            {message && <p className="success">{message}</p>}
            {error && <p className="error">{error}</p>}
            <button onClick={closeReturnPopup} className="close-popup-btn">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReturnOrder;
