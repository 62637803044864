import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import './account.css'; // Optional: Add CSS for styling
import UserProfile from './UserProfile';
import MyOrders from '../my account/MyOrders'; // Import MyOrders component
import ForgetPassword from '../my account/ForgetPassword'; // Import ForgetPassword component
import UserReview from './UserReview';
import ReturnOrder from './ReturnOrder';
import MyReturnedOrders from './MyReturnedOrders';

const Account = () => {
  const menuItems = [
    { name: 'User Profile', path: '/account/userprofile' },
    { name: 'My Orders', path: '/account/myorder' },
    { name: 'Returned Orders', path: '/account/returned' },
    { name: 'User Review', path: '/account/userreview' },
    { name: 'Change Password', path: '/account/forget-password' },
    
    
  ];

  return (
    <div className="account-container">
      <div className="account-dropdown">
        <ul className="dropdown-menu">
          {menuItems.map((item, index) => (
            <li key={index} className="dropdown-menu-item">
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="account-details">
        <Routes>
          <Route index element={<UserProfile />} /> {/* Default route */}
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/myorder" element={<MyOrders />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/userreview" element={<UserReview />} />
          <Route path="/return-order" element={<ReturnOrder />} />
          <Route path="/returned" element={<MyReturnedOrders />} />
        </Routes>
      </div>
    </div>
  );
};

export default Account;
