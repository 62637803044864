import React, { useState } from 'react';
import './privacyPolicy.css';

const TermsAndConditions = () => {
  const [isIntroductionVisible, setIsIntroductionVisible] = useState(false);
  const [isUserResponsibilitiesVisible, setIsUserResponsibilitiesVisible] = useState(false);
  const [isLimitationsVisible, setIsLimitationsVisible] = useState(false);
  const [isGoverningLawVisible, setIsGoverningLawVisible] = useState(false);
  const [isChangesVisible, setIsChangesVisible] = useState(false);
  const [isAccountSecurityVisible, setIsAccountSecurityVisible] = useState(false);
  const [isPaymentMethodsVisible, setIsPaymentMethodsVisible] = useState(false);
  const [isShippingVisible, setIsShippingVisible] = useState(false);
  const [isReturnsVisible, setIsReturnsVisible] = useState(false);
  const [isIntellectualPropertyVisible, setIsIntellectualPropertyVisible] = useState(false);

  const toggleIntroduction = () => {
    setIsIntroductionVisible(!isIntroductionVisible);
  };

  const toggleUserResponsibilities = () => {
    setIsUserResponsibilitiesVisible(!isUserResponsibilitiesVisible);
  };

  const toggleLimitations = () => {
    setIsLimitationsVisible(!isLimitationsVisible);
  };

  const toggleGoverningLaw = () => {
    setIsGoverningLawVisible(!isGoverningLawVisible);
  };

  const toggleChanges = () => {
    setIsChangesVisible(!isChangesVisible);
  };

  const toggleAccountSecurity = () => {
    setIsAccountSecurityVisible(!isAccountSecurityVisible);
  };

  const togglePaymentMethods = () => {
    setIsPaymentMethodsVisible(!isPaymentMethodsVisible);
  };

  const toggleShipping = () => {
    setIsShippingVisible(!isShippingVisible);
  };

  const toggleReturns = () => {
    setIsReturnsVisible(!isReturnsVisible);
  };

  const toggleIntellectualProperty = () => {
    setIsIntellectualPropertyVisible(!isIntellectualPropertyVisible);
  };

  return (
    <section id='PrivacyMain'>
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-header">
          Terms and Conditions
        </h1>
        <div className='headingTop'>
          <p><strong>Last Updated: [21 August 2024]</strong></p>

          <center><h2>Introduction</h2></center>
          <p1>Welcome to WesternFlex.com. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy, govern Western Flex's relationship with you in relation to this website.</p1>

          <h3 onClick={toggleIntroduction}>Introduction</h3>
          <div className={`privacy-policy-content ${isIntroductionVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>These terms and conditions outline the rules and regulations for the use of Western Flex's website.</li>
              <li>By accessing this website, you accept these terms and conditions in full.</li>
            </ul>
          </div>

          <h3 onClick={toggleUserResponsibilities}>User Responsibilities</h3>
          <div className={`privacy-policy-content ${isUserResponsibilitiesVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>You must use the website in compliance with all applicable laws and regulations.</li>
              <li>You agree not to use the website for any unlawful purpose.</li>
              <li>You are responsible for maintaining the confidentiality of your account and password.</li>
            </ul>
          </div>

          <h3 onClick={toggleAccountSecurity}>Account Security</h3>
          <div className={`privacy-policy-content ${isAccountSecurityVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>You are responsible for maintaining the confidentiality of your account details and for any activities that occur under your account.</li>
              <li>We reserve the right to suspend or terminate accounts that we suspect may have been compromised or used in violation of these terms.</li>
            </ul>
          </div>

          <h3 onClick={togglePaymentMethods}>Payment Methods</h3>
          <div className={`privacy-policy-content ${isPaymentMethodsVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>We accept various forms of payment including credit cards, debit cards, and online payment gateways.</li>
              <li>All transactions are subject to authorization and validation checks.</li>
              <li>We reserve the right to cancel any order if payment cannot be processed or verified.</li>
            </ul>
          </div>

          <h3 onClick={toggleShipping}>Shipping and Delivery</h3>
          <div className={`privacy-policy-content ${isShippingVisible ? 'show' : 'hide'}`}>
            <ul>
              <div className="shipping-policy-container">
      <h1 className="policy-heading">Western Flex Shop Shipping Policy</h1>
      <p className="policy-text">Country: India</p>
      
      <h2 className="policy-subheading">Delivery Time</h2>
      <p className="policy-text">
        All orders are estimated to be delivered within 3-7 business days. This timeframe includes both handling and transit times.
      </p>
      
      <h2 className="policy-subheading">Order Cut-Off Time</h2>
      <p className="policy-text">
        Orders placed before 2:00 PM (GMT+05:30) will be processed the same day. Orders placed after this cut-off time will be processed the next business day.
      </p>
      
      <h2 className="policy-subheading">Handling Time</h2>
      <ul className="policy-list">
        <li>Minimum: 0 days (orders placed before the cut-off time will be shipped the same day)</li>
        <li>Maximum: 1 day</li>
        <li>Fulfilled from Monday to Saturday.</li>
      </ul>
      
      <h2 className="policy-subheading">Transit Time</h2>
      <ul className="policy-list">
        <li>Minimum: 3 days</li>
        <li>Maximum: 6 days</li>
        <li>Shipped from our facility Monday to Saturday.</li>
      </ul>
      
      <h2 className="policy-subheading">Shipping Cost</h2>
      <p className="policy-text">
        We offer free shipping on all orders, so you can shop with peace of mind!
      </p>
      
      <h2 className="policy-subheading">Public Holidays</h2>
      <p className="policy-text">
        Please note that delivery estimates may be adjusted if your order is affected by public holidays.
      </p>
      
      <p className="policy-text">
        For any questions regarding your order or our shipping policy, please contact our customer support. Thank you for choosing Western Flex Shop!
      </p>
    </div>
            </ul>
          </div>

          <h3 onClick={toggleReturns}>Returns and Refunds</h3>
          <div className={`privacy-policy-content ${isReturnsVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>Returns are accepted within 30 days of purchase if the product is unused and in its original packaging.</li>
              <li>Refunds will be processed to the original payment method within 14 business days of receiving the returned product.</li>
              <li>Return shipping costs are the responsibility of the customer, unless the product is defective.</li>
            </ul>
          </div>

          <h3 onClick={toggleIntellectualProperty}>Intellectual Property</h3>
          <div className={`privacy-policy-content ${isIntellectualPropertyVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>All content on this website, including but not limited to text, graphics, logos, and images, is the property of Western Flex or its content suppliers and is protected by intellectual property laws.</li>
              <li>You may not reproduce, distribute, or create derivative works from any content found on this site without prior written permission from Western Flex.</li>
            </ul>
          </div>

          <h3 onClick={toggleLimitations}>Limitations of Liability</h3>
          <div className={`privacy-policy-content ${isLimitationsVisible ? 'show' : 'hide'}`}>
            <ul>
              <li>Western Flex will not be liable for any damages that may arise from your use of the website.</li>
              <li>All information provided on this website is subject to change without notice.</li>
            </ul>
          </div>

          <h3 onClick={toggleGoverningLaw}>Governing Law</h3>
          <div className={`privacy-policy-content ${isGoverningLawVisible ? 'show' : 'hide'}`}>
            <p1>These terms and conditions are governed by and construed in accordance with the laws of India , and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p1>
          </div>

          <h3 onClick={toggleChanges}>Changes to Terms and Conditions</h3>
          <div className={`privacy-policy-content ${isChangesVisible ? 'show' : 'hide'}`}>
            <p1>Western Flex reserves the right to amend these terms and conditions at any time without notice.</p1>
          </div>

        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
