import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import Product from '../pages/Product'; 
import './shop.css';
import './home.css';
import saleBanner from '../components/assets/Sale Banner.avif';
import backpackImage from '../components/assets/backpack.png';
import phonebanner from '../components/assets/phonebanner.avif';

// Font Awesome imports for shopping cart icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';  // Icon for the button

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [lock, setLock] = useState(false);
  const productsPerPage = 16;
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const slides = [saleBanner];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };
  
  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchFeaturedProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/featured-products`);
        setFeaturedProducts(response.data);
      } catch (error) {
        console.error('Error fetching featured products:', error);
      }
    };

    fetchProducts();
    fetchFeaturedProducts();
  }, []);

  const handleAddToCart = (product) => {
    if (!lock) {
      setLock(true);
      addToCart(product);
      setTimeout(() => {
        setLock(false);
      }, 500);
    }
  };

  const handleBannerClick = () => {
    navigate('/shop');
  };

  const renderProducts = (productsList) => {
    const filteredProducts = productsList.filter((product) =>
      product.colors.some(
        (color) =>
          [0].includes(color.priority) &&
          color.images.length > 0 &&
          (!selectedPriceRange || product.sellingPrice < selectedPriceRange)
      )
    );

    const paginatedProducts = filteredProducts.slice(
      currentPage * productsPerPage,
      (currentPage + 1) * productsPerPage
    );

    return (
      <div className="product-container">
        {paginatedProducts.map((product) =>
          product.colors.map((color) => {
            if ([0].includes(color.priority) && color.images.length > 0) {
              return (
                <Product
                  key={`${product._id}-${color.color}`}
                  product={product}
                  color={color}
                  onAddToCart={handleAddToCart}
                  onVisible={() => {
                    /* Optionally handle visibility */
                  }}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </div>
    );
  };

  return (
    <div>
      {/* Meta Tags for SEO */}
      <head>
        <title>Western Flex Shop - Stylish and Durable Bagpacks</title>
        <meta name="description" content="Shop the best selection of bagpack at Western Flex Shop. Enjoy free shipping, quality guaranteed, and easy returns. Discover your perfect bagpack today!" />
      </head>

      <div onClick={handleBannerClick}>
        <section className="section1">
          <div className="img-slider">
            {slides.map((slide, index) => (
              <img
                key={index}
                src={slide}
                alt={`Slide ${index}`}
                className={index === currentSlide ? 'img active' : 'img'}
              />
            ))}
          </div>
          <img src={backpackImage} alt="Backpack" className="backpack-image" />
        </section>
      </div>

      <div className="phone-banner" onClick={handleBannerClick}>
        <img src={phonebanner} alt="Phone Sale Banner" />
      </div>

      <div>
        <h1>Best Selling Products</h1>
        {renderProducts(products)}

        {/* Shop Now Button */}
        <div className="shop-now-button-container">
          <button className="shop-now-button" onClick={handleBannerClick}>
            <FontAwesomeIcon icon={faShoppingCart} /> Shop Now
          </button>
        </div>

        {/* Added Text Content */}
        <div className="non-selectable-text-container">
          <h2>Welcome to Western Flex Shop – Your Premier Destination for Premium Bags</h2>
          <p>At Western Flex Shop, we specialize in delivering high-end, premium bags that combine elegance, durability, and luxury. Our exclusive collections range from luxury handbags to designer travel bags, all meticulously crafted to meet your style and functional needs. Whether you're in search of the perfect leather handbag, a versatile tote bag, or a stylish backpack, we have the ideal collection for every occasion.</p>

          <h3>Why Western Flex Shop is the Best Choice for Premium Bags</h3>
          <ul>
            <li><strong>Luxury Design & Style:</strong> Our extensive range of premium bags reflects both contemporary trends and timeless classics, designed to elevate your style while providing practicality.</li>
            <li><strong>High-Quality Materials:</strong> Crafted from top-grade materials like genuine leather and eco-friendly fabrics, our bags are built to last.</li>
            <li><strong>Handcrafted Artistry:</strong> Every bag is meticulously handcrafted by skilled artisans, ensuring superior craftsmanship and intricate detailing.</li>
            <li><strong>Own Manufacturing:</strong> As a manufacturer, we control every aspect of the production process to ensure the highest quality for our customers.</li>
            <li><strong>Diverse Collection for All Occasions:</strong> We offer a wide selection of bags for every occasion, from professional work bags or a stylish evening clutch.</li>
          </ul>

          <h3>Why Customers Love Western Flex Shop</h3>
          <ul>
            <li><strong>Fast & Free Shipping:</strong> We offer free shipping on all orders across the India with a commitment to fast delivery.</li>
            <li><strong>Eco-Friendly & Sustainable:</strong> Our premium bags are made from sustainable materials, ensuring an eco-friendly shopping experience.</li>
            <li><strong>Excellent Customer Support:</strong> Our 5-star customer service team is here to assist with any product or order queries.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Shop;
