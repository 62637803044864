import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './adminqueries.css'; // Uncomment and create appropriate CSS styling

const AdminQueries = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // 'all', 'solved', 'unsolved'

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('Authentication token not found. Please log in.');
          setLoading(false);
          return;
        }

        const response = await axios.get('/api/admin/queries', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setQueries(response.data);
        } else {
          setError(response.data.message || 'No queries found');
        }
      } catch (error) {
        console.error('Error fetching queries:', error);
        setError('An error occurred while fetching queries.');
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, []);

  const updateStatus = async (queryId, newStatus) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Authentication token not found. Please log in.');
        return;
      }

      const response = await axios.put(`/api/admin/queries/${queryId}`, { status: newStatus }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setQueries(queries.map(query =>
          query._id === queryId ? { ...query, status: newStatus } : query
        ));
      } else {
        setError(response.data.message || 'Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      setError('An error occurred while updating the status.');
    }
  };

  const filteredQueries = queries.filter(query => 
    filter === 'all' || query.status === filter
  );

  if (loading) {
    return <p>Loading queries...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-queries-container">
      <h2>User Queries</h2>
      <div className="filter-options">
        <label>Filter by Status: </label>
        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="solved">Solved</option>
          <option value="unsolved">Unsolved</option>
        </select>
      </div>
      {filteredQueries.length === 0 ? (
        <p>No queries found.</p>
      ) : (
        <table className="queries-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredQueries.map(query => (
              <tr key={query._id}>
                <td>{query.name}</td>
                <td>{query.email}</td>
                <td>{query.phone}</td>
                <td>{query.message}</td>
                <td>{new Date(query.createdAt).toLocaleDateString()}</td>
                <td>{query.status === 'solved' ? 'Solved' : 'Unsolved'}</td>
                <td>
                  {query.status === 'unsolved' ? (
                    <button onClick={() => updateStatus(query._id, 'solved')}>Mark as Solved</button>
                  ) : (
                    <button onClick={() => updateStatus(query._id, 'unsolved')}>Mark as Unsolved</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminQueries;
