import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './myReturnedOrders.css'; // Import the CSS

const MyOrdersReturned = () => {
  const [returnedOrders, setReturnedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReturnedOrders = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve user token from localStorage
        console.log('Token:', token); // Debugging: check if token is available

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/returned-orders`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        });

        console.log('Response:', response.data); // Debugging: check response data
        setReturnedOrders(response.data); // Set the returned orders in state
        setLoading(false);
      } catch (err) {
        console.error('API Error:', err.response ? err.response.data : err.message); // Debugging: log full error
        setError(err.message);
        setLoading(false);
      }
    };

    fetchReturnedOrders();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="returned-orders-container">
      <h2>My Returned Orders</h2>
      {returnedOrders.length === 0 ? (
        <p>No returned orders found.</p>
      ) : (
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Phone Number</th>
                <th>Reason</th>
                <th>Returned Items</th>

              </tr>
            </thead>
            <tbody>
              {returnedOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.orderId}</td>
                  <td>{order.phoneNumber}</td>
                  <td>{order.reason}</td>
                  <td>
                    <ul>
                      {order.returnedItems.map((item, idx) => (
                        <li key={idx}>{item.itemName}</li>
                      ))}
                    </ul>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MyOrdersReturned;
