import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './register.css';
import UserReg from '../components/assets/registeruser.png';
import { FaUser, FaLock, FaEnvelope, FaPhone, FaUserPlus, FaQuestionCircle } from 'react-icons/fa';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    username: '',
    password: '',
    re_pass: '',
    email: '',
    phno: '',
    securityQuestion: '',
    securityAnswer: ''
  });

  const [error, setError] = useState('');
  const [errorField, setErrorField] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the input is for the username
    if (name === 'username') {
      // Remove spaces and convert to lowercase
      const modifiedValue = value.replace(/\s+/g, '').toLowerCase();
      setFormData({
        ...formData,
        [name]: modifiedValue
      });
    } else if (name === 'phno') {
      // Remove spaces from phone number
      const modifiedValue = value.replace(/\D/g, '');
      setFormData({
        ...formData,
        [name]: modifiedValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };  

  const validatePhoneNumber = (phno) => {
    return phno.length === 10;
  };

  const validateForm = () => {
    if (formData.username.length < 4) {
      setError('Username must be at least 4 characters long');
      setErrorField('username');
      return false;
    }
    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters long');
      setErrorField('password');
      return false;
    }
    if (formData.password !== formData.re_pass) {
      setError('Passwords do not match');
      setErrorField('re_pass');
      return false;
    }
    if (!formData.securityQuestion) {
      setError('Please select a security question');
      setErrorField('securityQuestion');
      return false;
    }
    if (!formData.securityAnswer) {
      setError('Please provide an answer to the security question');
      setErrorField('securityAnswer');
      return false;
    }
    if (!validatePhoneNumber(formData.phno)) {
      setError('Phone number must be 10 digits');
      setErrorField('phno');
      return false;
    }
    // Skip email validation if it's empty
    return true;
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validateForm()) return;

  try {
    const response = await fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    const data = await response.json();

    if (response.ok) {
      // Store the token in localStorage
      localStorage.setItem('token', data.token);

      // Redirect to the shop page and refresh to ensure the user is logged in
      navigate('/shop');
      window.location.reload(); // Automatically refresh the shop page after navigating
    } else {
      setError(data.message);
      setErrorField(data.field);
      alert(data.message);
    }
  } catch (error) {
    console.error('Error:', error);
    setError('Error registering user');
  }
};


  return (
    <div className="main">
      <section className="signup">
        <div className="register-container">
          <div className="signup-content">
            <div className="signup-form">
              <h2 className="form-title">Sign Up</h2>
              <form method="post" className="register-form" id="register-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <FaUser className="icon" />
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder=" Enter First Name"
                    className={`input1 ${errorField === 'fname' ? 'error-input' : ''}`}
                    value={formData.fname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaUser className="icon" />
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    placeholder="Enter Last Name"
                    className={`input1 ${errorField === 'lname' ? 'error-input' : ''}`}
                    value={formData.lname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaUserPlus className="icon" />
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter your Username"
                    className={`input1 ${errorField === 'username' ? 'error-input' : ''}`}
                    value={formData.username}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaLock className="icon" />
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your Password"
                    className={`input1 ${errorField === 'password' ? 'error-input' : ''}`}
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaLock className="icon" />
                  <input
                    type="password"
                    name="re_pass"
                    id="re_pass"
                    placeholder="Repeat your password"
                    className={`input1 ${errorField === 're_pass' ? 'error-input' : ''}`}
                    value={formData.re_pass}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaEnvelope className="icon" />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your Email (optional)"
                    className={`input1 ${errorField === 'email' ? 'error-input' : ''}`}
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <FaPhone className="icon" />
                  <input

                    type="text"
                    name="phno"
                    id="phno"
                    placeholder="Enter your Phone no."
                    className={`input1 ${errorField === 'phno' ? 'error-input' : ''}`}
                    value={formData.phno}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group security-question">
                  <FaQuestionCircle className="icon" />
                  <select
                    name="securityQuestion"
                    id="securityQuestion"
                    className={`input1 ${errorField === 'securityQuestion' ? 'error-input' : ''}`}
                    value={formData.securityQuestion}
                    onChange={handleChange}
                  >
                    <option value="">Select a Security Question</option>
                    <option value="What is your pet's name?">What is your pet's name?</option>
                    <option value="What is your mother's maiden name?">What is your mother's maiden name?</option>
                    <option value="What was your first car?">What was your first car?</option>
                    <option value="What elementary school did you attend?">What elementary school did you attend?</option>
                    <option value="What is the name of your favorite teacher?">What is the name of your favorite teacher?</option>
                  </select>
                </div>
                <div className="form-group security-answer">
                  <input
                    type="text"
                    name="securityAnswer"
                    id="securityAnswer"
                    placeholder="Enter your answer"
                    className={`input1 ${errorField === 'securityAnswer' ? 'error-input' : ''}`}
                    value={formData.securityAnswer}
                    onChange={handleChange}
                  />
                </div>

                
                <div className="form-group-Policy">
                <p1>I accept the Privacy Policy and Terms & Conditions</p1>
                </div>
                <div className="form-group form-button">
                  <button type="submit" className="form-submit1">Register</button>
                </div>
                {error && <div className="error-message">{error}</div>}
              </form>
            </div>
            <div className="signup-image">
              <div className='iconSignup'>
              {/* <figure><FaUserPlus className="register-icon" /></figure> */}
                <img src={UserReg} alt="user registration" />
              </div>
              <div className='signup-image-link'>
                <Link to="/login" className='link-singup'>Already a member? <u>Click here</u></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterPage;
