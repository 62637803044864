import React from 'react';
import './footer.css';
import freeDelivery from '../components/assets/freedelivery.png';
import cashOn from '../components/assets/cod.png';
import domesticShipping from '../components/assets/delivery.png';
import qualityGuarantee from '../components/assets/quality.png';
import easyReturn from '../components/assets/refund1.png';
import linkedinImg from '../components/assets/linkedin.png';
import instaImg from '../components/assets/instagram.png';
import facebookImg from '../components/assets/facebook.png';
import creditImg from '../components/assets/credit-card.png';

const Footer = () => {
    return (
        <footer className="footer">
            {/* New promotional text */}
            <div className="promo-text">
                <h2>Explore Our Latest Premium Bags Collection at Western Flex Shop</h2>
                <p>Don't miss out on our newest arrivals! Shop premium handbags, leather bags, and designer travel bags today at Western Flex Shop. Elevate your style with bags that blend luxury, functionality, and craftsmanship. Explore our collections now and find the perfect bag for any occasion.</p>
            </div>

            <div className="footer-container">
                <div className="footer-item">
                    <img src={creditImg} alt="sign up" />
                    <p>Secure Checkout</p>
                </div>
                <div className="footer-item">
                    <img src={freeDelivery} alt="Free Shipping" />
                    <p>Free Shipping</p>
                </div>
                <div className="footer-item">
                    <img src={easyReturn} alt="Quality Guaranteed" />
                    <p>Easy Return within 4-5 days</p>
                </div>
                <div className="footer-item">
                    <img src={cashOn} alt="Cash On Delivery" />
                    <p>Cash On Delivery</p>
                </div>
                <div className="footer-item">
                    <img src={domesticShipping} alt="5-7 Days Domestic Shipping" />
                    <p>3-7 Days Shipping</p>
                </div>
                <div className="footer-item">
                    <img src={qualityGuarantee} alt="Quality Guaranteed" />
                    <p>Quality Guaranteed</p>
                </div>
            </div>

            <div className="Policy">
                <ul>
                    <a href='/terms-and-conditions'><li>Terms and Conditions</li></a>
                    <a href='/Privacy-policy'><li>Privacy Policy</li></a>
                    <a href='/return-policy'><li>Return Policy</li></a>
                    <a href='/shipping-policy'><li>Shipping Policy</li></a>
                    <a href='/about-us'><li>About Us</li></a>
                    <a href='/contact-us'><li>Contact Us</li></a>
                </ul>
            </div>

            <div className="footer-container">
                <div className="footer-section">
                    <h3>FOLLOW</h3>
                    <div className="social-icons-footer">
                        <a href="https://www.linkedin.com/company/westernflex/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinImg} alt="LinkedIn" />
                        </a>
                        <a href="https://www.instagram.com/westernflexshop" target="_blank" rel="noopener noreferrer">
                            <img src={instaImg} alt="Instagram" />
                        </a>
                        <a href="https://facebook.com/westernflexshop" target="_blank" rel="noopener noreferrer">
                            <img src={facebookImg} alt="Facebook" />
                        </a>
                    </div>
                </div>

                <div className="footer-section">
                    <address>
                        <p>Address:-</p>
                        <p>212B Floor-GRND</p>
                        <p>Abubakar Bagicha Compound</p>
                        <p>Mumbai PIN:400017</p>
                    </address>
                    <div className="contacts">
                        <p>Email: info@westernflex.com</p>
                    </div>
                </div>
            </div>

            {/* Copyright Notice */}
            <div className="footer-bottom">
                <p>© 2024 Western Flex. All Rights Reserved.</p>
            </div>
        </footer>
    );
}


export default Footer;
